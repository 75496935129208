import React from 'react';

import {Release} from '../styles';

export default () => (

	<Release className="w-100 d-flex justify-content-center align-items-center pr-3 pl-3 position-relative">

		<h2 className="sr-only">

			Release - David Dias

		</h2>

		<img src={require('~/assets/img/index/release.png')} alt="David Dias"
		title="David Dias" width="1440" height="895" className="img-fluid d-none d-lg-inline-block" />

		<img src={require('~/assets/img/index/release-mobile.jpg')} alt="David Dias"
		title="David Dias" width="739" height="656" className="img-fluid d-inline-block d-lg-none" />

	</Release>

);