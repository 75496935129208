import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import { faFacebook, faInstagram, faTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons';

import {Footer} from '../styles';

const componentFooter = props => (

	<Footer className="w-100 d-flex flex-column pr-md-5 pl-md-5">

		<div className="d-flex justify-content-between align-items-center flex-column flex-md-row">

			<a href={props.link_contratantes} title="Acesso exclusivo contratantes" target="_blank" rel="noopener noreferrer">

				<img src={require('~/assets/img/index/contratantes.png')} alt="Contratantes" title="Contratantes" width="177" height="78" />

			</a>

			<div className="d-flex flex-column mt-5 mt-md-0">

				<div className="d-flex justify-content-center justify-content-md-end align-items-center">

					{props.facebook && (

						<a href={`https://www.facebook.com/${props.facebook}`} title="Curta no Facebook" 
						className="mr-2 ml-2 icon-social" target="_blank" rel="noopener noreferrer">

							<FontAwesomeIcon icon={faFacebook} />

						</a>

					)}

					{props.instagram && (

						<a href={`https://www.instagram.com/${props.instagram}`} title="Siga no Instagram" 
						className="mr-2 ml-2 icon-social" target="_blank" rel="noopener noreferrer">

							<FontAwesomeIcon icon={faInstagram} />

						</a>

					)}

					{props.twitter && (

						<a href={`https://www.twitter.com/${props.twitter}`} title="Siga no Twitter" 
						className="mr-2 ml-2 icon-social" target="_blank" rel="noopener noreferrer">

							<FontAwesomeIcon icon={faTwitter} />

						</a>

					)}

					{props.youtube && (

						<a href={props.youtube} title="Se inscreva no canal do YouTube" 
						className="mr-2 ml-2 icon-social" target="_blank" rel="noopener noreferrer">

							<FontAwesomeIcon icon={faYoutube} />

						</a>

					)}

				</div>

				<a href={`https://api.whatsapp.com/send?1=pt_BR&phone=55${props.cell_phone}`} target="_blank"
				title="Entrar em contato no WhatsApp" className="Augusto text-center text-md-right phone" rel="noopener noreferrer">

					{props.cell_phone}

				</a>


				<a href={`tel:${props.phone}`} title="Ligar" className="Augusto text-center text-md-right phone">

					{props.phone}

				</a>

				<a href={`mailto:${props.email}`} title="Enviar E-mail" className="Augusto text-center text-md-right mt-2 email">

					{props.email}

				</a>

			</div>

		</div>


		<div className="mt-5 pt-5 pb-5 d-flex justify-content-center">

			<a href="https://www.icomp.com.br" title="Desenvolvido por Agência ICOMP" target="_blank" rel="noopener noreferrer">

				<img src={require('~/assets/img/index/icomp.png')} alt="Desenvolvido por Agência ICOMP" 
				title="Desenvolvido por Agência ICOMP" width="273" height="39" className="img-fluid" />

			</a>

		</div>

	</Footer>

);

export default componentFooter;