import React from 'react';

import {Events} from '../styles';

import {Carousel} from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";

const componentEvents = props => {

	if(props){

		return(

			<Events className="position-relative pr-3 pl-3 pr-xl-0 pl-xl-0">

				<h2 className="sr-only">

					Agenda de shows David Dias

				</h2>

				<Carousel className="bg-events d-flex justify-content-start align-items-center" showThumbs={false} showStatus={false} 
				showArrows={false} showIndicators={props.length > 1 ? true : false}>

					{Object.values(props).map((event, index) => (

						<article key={index} className="d-flex justify-content-start align-items-center flex-column flex-xl-row">

							<h3 className="sr-only">

								{event.title}

							</h3>

							<strong className="day-month BohemPress text-uppercase d-flex justify-content-center align-items-start mb-5 mb-xl-0">

								<span className="mr-3">

									{event.day}

								</span>

								<span>

									{event.monthAb}

								</span>

							</strong>

							<span className="city-uf LemonMilk text-uppercase text-center text-xl-left ml-xl-5">

								{event.city} / {event.uf}

							</span>

						</article>

					))}

				</Carousel>

			</Events>

		);

	}else{

		return null;

	}

};

export default componentEvents;