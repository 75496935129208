import styled from 'styled-components';

export const Fragment = styled.div`
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	overflow-x: hidden;
	background: url(${require('~/assets/img/index/bg-body.jpg')}) center bottom no-repeat #EFE6D3;

	@font-face{
		font-family: 'Augusto';
		src: url(${require('../assets/fonts/Augusto/Augusto.woff2')}) format('woff2'),
         	url(${require('../assets/fonts/Augusto/Augusto.woff')}) format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: auto;
	}	
	@font-face{
		font-family: 'BohemPress';
		src: url(${require('../assets/fonts/BohemPress/BohemPress.woff2')}) format('woff2'),
         	url(${require('../assets/fonts/BohemPress/BohemPress.woff')}) format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: auto;
	}
	@font-face{
		font-family: 'GothamBlack';
		src: url(${require('../assets/fonts/GothamBlack/GothamBlack.woff2')}) format('woff2'),
         	url(${require('../assets/fonts/GothamBlack/GothamBlack.woff')}) format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: auto;
	}
	@font-face{
		font-family: 'GothamLight';
		src: url(${require('../assets/fonts/GothamLight/GothamLight.woff2')}) format('woff2'),
         	url(${require('../assets/fonts/GothamLight/GothamLight.woff')}) format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: auto;
	}
	@font-face{
		font-family: 'LemonMilk';
		src: url(${require('../assets/fonts/LemonMilk/LemonMilk.woff2')}) format('woff2'),
         	url(${require('../assets/fonts/LemonMilk/LemonMilk.woff')}) format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: auto;
	}

	.Augusto{
		font-family: "Augusto";
	}
	.BohemPress{
		font-family: "BohemPress";
	}
	.GothamBlack{
		font-family: "GothamBlack";
	}
	.GothamLight{
		font-family: "GothamLight";
	}
	.LemonMilk{
		font-family: "LemonMilk";
	}

	a{
		text-decoration: none !important;
		&.indent{
			text-indent: -9999px;
		}
	}

	.carousel .slide{
		background: transparent !important;
		img{
			width: auto;
		}
	}

	.carousel.carousel-slider{
		overflow: visible !important;
		ul.control-dots{
			bottom: -3rem;
			padding-left: 0px;
			li{
				width: 13px;
				height: 13px;
				border-radius: 50%;
				box-shadow: none;
				border: 1px solid #46101A;
				outline: none;
				background: transparent;
				&.selected{
					background: #46101A;
				}
			}
		}
	}

	.transition{
		transition: 0.5s;
	}

	.wow{
		visibility: hidden;
	}

	.text-dark{
		color: #333 !important;
	}
	.bg-dark{
		background: #333 !important;
	}

	@keyframes floating{
		from{
			transform: translate(0,0px);
		}
		65%{
			transform:translate(0,15px);
		}
		to{
			transform:translate(0,0px);
		}
	}
	.floating{
		animation-name: floating;
		animation-duration: 3s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in-out;
	}

	div.bg-loader-site{
		z-index: 9999;
		top: 0px;
		left: 0px;
		background-color: rgba(0,0,0,0.6);
		div.loader-site{
			width: 50px;
			svg.circular{
				height: 50px;
				animation: rotate 2s linear infinite;
				transform-origin: center center;
				.path{
					stroke-dasharray: 1, 200;
					stroke-dashoffset: 0;
					animation: dash 1.5s ease-in-out infinite, color 3s ease-in-out infinite;
					stroke-linecap: round;
				}
			}
		}
		&.hidden{
			opacity: 0;
			pointer-events: none;
		}
	}
	@keyframes rotate{
		100%{
			transform: rotate(360deg);
		}
	}
	@keyframes dash{
		0%{
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
		}
		50%{
			stroke-dasharray: 89, 200;
			stroke-dashoffset: -35px;
		}
		100%{
			stroke-dasharray: 89, 200;
			stroke-dashoffset: -124px;
		}
	}
	@keyframes color{
		100%, 0%{
			stroke: #EDB665;
		}
		70%{
			stroke: #DD555A;
		}
	}

`;