import React from 'react';

import {Launch} from '../styles';

import ReactWOW from 'react-wow';

export default class componentLaunch extends React.Component{

	state = {
		pictures: {}
	}

	async componentDidMount(){

		const response = await fetch(`https://api.instagram.com/v1/users/self/media/recent/?access_token=1314027380.1677ed0.4ed8985bd8864019ada38804139b4919&count=6`);

		const instagram = await response.json();

		await this.setState({
			pictures: instagram.data
		});

	}

	changeVideo = (e) => {

		document.querySelector('div.bg-video').innerHTML = '<iframe title="' + this.props.institutional.title_launch + '" src="https://www.youtube.com/embed/' + this.props.institutional.video_id_launch + '?autoplay=1" class="position-absolute w-100 h-100"></iframe>';

		document.querySelector('div.bg-video').classList.add('active');

	};

	render(){

		return(

			<Launch className={`position-relative${!this.state.events ? ' no-events' : ''}`}>

				<div className="container position-relative d-flex flex-column mb-5">

					<ReactWOW animation="fadeInUp" duration="2s">

						<h2 className="Augusto w-100 text-center position-absolute mt-4 mb-4 mt-lg-0 mb-lg-0">

							<span className="position-absolute w-100 d-none d-lg-inline-block"></span>

							<span className="position-relative pr-lg-5 pl-lg-5">

								{this.props.institutional.title_launch}

							</span>

						</h2>

					</ReactWOW>


					<ReactWOW animation="fadeIn" duration="2s">

						<div className="bg-video position-relative">

							<button onClick={this.changeVideo} className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">

								<img src={require('~/assets/img/index/play.png')} alt="Play" title="Play" width="84" height="84" />

							</button>

							<img src={`https://img.youtube.com/vi/${this.props.institutional.video_id_launch}/maxresdefault.jpg`} alt={this.props.institutional.title_launch}
							title={this.props.institutional.title_launch} width="1280" height="720" className="img-fluid w-100" />

						</div>

					</ReactWOW>


					{(this.props.institutional.spotify || this.props.institutional.deezer || this.props.institutional.youtube_music || this.props.institutional.google_play || this.props.institutional.itunes) && (

						<div className="w-100 d-flex justify-content-center justify-content-lg-between align-items-center flex-wrap mt-4 mt-lg-0 mb-5">

							{this.props.institutional.spotify && (

								<ReactWOW animation="fadeInUp" duration="2s">

									<a href={this.props.institutional.spotify} title="Ouça no Spotify" target="_blank" rel="noopener noreferrer">

										<img src={require('~/assets/img/index/spotify.png')} alt="Ouça no Spotify"
										title="Ouça no Spotify" width="143" height="55" className="img-fluid mb-3 mr-3 mr-lg-2" />

									</a>

								</ReactWOW>

							)}

							{this.props.institutional.deezer && (

								<ReactWOW animation="fadeInUp" delay="0.1s" duration="2s">

									<a href={this.props.institutional.deezer} title="Ouça no Deezer" target="_blank" rel="noopener noreferrer">

										<img src={require('~/assets/img/index/deezer.png')} alt="Ouça no Deezer"
										title="Ouça no Deezer" width="143" height="55" className="img-fluid mb-3 ml-3 mr-3 ml-lg-2 mr-lg-2" />

									</a>

								</ReactWOW>

							)}

							{this.props.institutional.youtube_music && (

								<ReactWOW animation="fadeInUp" delay="0.2s" duration="2s">

									<a href={this.props.institutional.youtube_music} title="Ouça no YouTube Music" target="_blank" rel="noopener noreferrer">

										<img src={require('~/assets/img/index/youtube-music.png')} alt="Ouça no YouTube Music"
										title="Ouça no YouTube Music" width="142" height="55" className="img-fluid mb-3 ml-3 mr-3 ml-lg-2 mr-lg-2" />

									</a>

								</ReactWOW>

							)}

							{/*this.props.institutional.google_play && (

								<ReactWOW animation="fadeInUp" delay="0.3s" duration="2s">

									<a href={this.props.institutional.google_play} title="Compre na Google Play" target="_blank" rel="noopener noreferrer">

										<img src={require('~/assets/img/index/google-play.png')} alt="Compre na Google Play"
										title="Compre na Google Play" width="182" height="55" className="img-fluid mb-3 ml-3 mr-3 ml-lg-2 mr-lg-2" />

									</a>

								</ReactWOW>

							)*/}

							{this.props.institutional.itunes && (

								<ReactWOW animation="fadeInUp" delay="0.4s" duration="2s">

									<a href={this.props.institutional.itunes} title="Ouça no Apple Music" target="_blank" rel="noopener noreferrer">

										<img src={require('~/assets/img/index/applemusic.png')} alt="Ouça no Apple Music"
										title="Ouça no Apple Music" width="121" height="55" className="img-fluid mb-3 ml-3 ml-lg-2" />

									</a>

								</ReactWOW>

							)}

						</div>

					)}

				</div>


				{this.props.institutional.instagram && (

					<React.Fragment>

						<a href={`https://instagram.com/${this.props.institutional.instagram}`} title="Siga no Instagram" target="_blank" rel="noopener noreferrer">

							<ReactWOW animation="fadeIn" duration="2s">

								<img src={require('~/assets/img/index/bg-instagram.png')} alt={this.props.institutional.instagram}
								title={this.props.institutional.instagram} width="1780" height="1605" className="img-fluid w-100 d-none d-lg-inline-block" />

							</ReactWOW>

						</a>

						<a href={`https://instagram.com/${this.props.institutional.instagram}`} title="Siga no Instagram" target="_blank" rel="noopener noreferrer">

							<ReactWOW animation="fadeIn" duration="2s">

								<img src={require('~/assets/img/index/bg-instagram-mobile.png')} alt={this.props.institutional.instagram}
								title={this.props.institutional.instagram} width="992" height="897" className="img-fluid w-100 d-inline-block d-lg-none" />

							</ReactWOW>

						</a>

						<div className="w-100 d-flex justify-content-between align-items-start flex-wrap mt-5 pt-lg-5">

							<div class="elfsight-app-527b829b-f63e-4494-8512-f340c770d6f6"></div>

						</div>

					</React.Fragment>

				)}

			</Launch>

		)

	}

};