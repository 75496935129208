import styled from 'styled-components';

export const Events = styled.section`
	margin-top: -190px;
	div.bg-events{
		width: 1200px;
		height: 496px;
		padding: 0px 0px 55px 140px;
		background: url(${require('~/assets/img/index/bg-events.png')}) center top no-repeat;
		background-size: 100% auto !important;
		article{
			padding-right: 80px;
			strong.day-month{
				color: #46101A;
				font-weight: normal;
				span:first-child{
					font-size: 190px;
					line-height: 210px;
				}
				span:last-child{
					font-size: 100px;
				}
			}
			span.city-uf{
				color: #B6804E;
				font-size: 58px;
				line-height: 70px;
			}
		}

		@media (max-width: 1200px){
			width: 100%;
			height: auto;
			padding: 0px;
			background: none;
			article{
				padding: 0px;
			}
			strong.day-month span{
				font-size: 100px !important;
				line-height: 100px !important;
			}
		}
	}

	@media (max-width: 992px){
		margin-top: 50px;
		div.bg-events article span.city-uf{
			font-size: 25px;
			line-height: 35px;
		}
	}
`;


export const Launch = styled.section`
	margin-top: 500px;
	background: url(${require('~/assets/img/index/bg-launch.jpg')}) center top no-repeat #311023;
	h2{
		font-weight: normal;
		font-size: 55px;
		color: #B6804E;
		left: 0px;
		top: -220px;
		span:first-child{
			height: 8px;
			top: 50%;
			left: 0px;
			transform: translate(0,-50%);
			background: #B6804E;
		}
		span:last-child{
			background: #EFE6D3;
		}
	}
	div.bg-video{
		top: -100px;
		overflow: hidden;
		iframe{
			top: 0px;
			left: 0px;
			border: 0px;
		}
		button{
			border: none;
			cursor: pointer;
			background: transparent;
		}
		&.active{
			padding-bottom: 56.25%;
		}
	}
	div.bg-picture{
		width: 16%;
		height: 317px;
		overflow: hidden;
		background: #311023;
		img{
			left: 50%;
			transform: translate(-50%, 0px);
		}
		a{
			text-indent: -9999px;
		}
		@media (max-width: 1200px){
			width: 33.33%;
		}
		@media (max-width: 768px){
			width: 50%;
		}
		@media (max-width: 576px){
			height: 200px;
		}
	}

	@media (max-width: 992px){
		margin-top: 100px !important;
		h2{
			top: 0px;
			font-size: 35px;
			position: relative !important;
			span{
				background: transparent !important;
			}
		}
		div.bg-video{
			top: 0px;
		}
	}

	&.no-events{
		margin-top: 250px;
	}
`;


export const Release = styled.footer`
	margin-top: -60px;
	@media (max-width: 992px){
		margin-top: 100px;
	}
`;


export const Footer = styled.footer`
	margin-top: 500px;
	a.phone{
		font-size: 60px;
		color: #680005;
		@media (max-width: 992px){
			font-size: 30px;
		}
	}
	a.icon-social{
		font-size: 25px;
		color: #680005;
	}
	a.email{
		font-size: 30px;
		color: #680005;
		@media (max-width: 992px){
			font-size: 23px;
		}
	}

	@media (max-width: 992px){
		margin-top: 300px;
	}
`;