import React from 'react';

export default () => (

	<header className="w-100 d-flex justify-content-end align-items-start">

		<h1 className="sr-only">

			David Dias

		</h1>

		<img src={require('~/assets/img/index/bg-header.png')} alt="David Dias"
		title="David Dias" width="1780" height="1605" className="img-fluid d-none d-lg-inline-block" />

		<img src={require('~/assets/img/index/bg-header-mobile.png')} alt="David Dias"
		title="David Dias" width="992" height="897" className="img-fluid d-inline-block d-lg-none" />

	</header>

);