import React from 'react';

import {baseUriWp, baseUri} from '~/services/api';

import Header from './components/header';

import Events from './components/events';

import Launch from './components/launch';

import Release from './components/release';

import Footer from './components/footer';

export default class Index extends React.Component{

	state = {
		institutional: {},
		events: {}
	}

	async componentDidMount() {

		await this.institutional();

		await this.events();

	}

	async institutional(){

		const response = await fetch(`${baseUriWp}/institucional/227`);

		const institutional = await response.json();

		await this.setState({
			institutional: institutional.acf
		});

	}

	async events(){

		const response = await fetch(`${baseUri}/events?user=6`);

		const eventsList = await response.json();

		await this.setState({
			events: eventsList.items
		});

	}

	render(){

		return(

			<React.Fragment>

				<Header />

				<Events {... this.state.events} />

				<Launch institutional={this.state.institutional} events={this.state.events} />

				<Release {... this.state.institutional} />

				<Footer {... this.state.institutional} />

			</React.Fragment>

		);

	}

}